import React from 'react';
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { Link } from "gatsby";

export default function Anchor(props) {
  const { className, href } = props;
  if (href.substr(0, 4) === 'http') {
    return <OutboundLink rel="noopener" href={href}>{props.children}</OutboundLink>
  }

  return <Link className={className} to={href}>{props.children}</Link>
}