import React, { useState, useCallback } from "react"

export default function Aside(props) {

  return (
    <div className="full-width">
      {props.children}
    </div>
  )
}
