import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";
import { graphql } from "gatsby";
import Map from "../Map/Map";
import Gallery from "../Gallery";
import Sidenote from "../Sidenote";
import SEO from "../SEO";
import Aside from "../Aside";
import Pull from "../Pull";
import FullWidth from "../FullWidth";
import TableOfContents from "../TableOfContents";
import Layout from "../layout"
import TextContainer from "../TextContainer/TextContainer"
// import textContainerStyles from "../TextContainer/TextContainer.module.sass"
import { Link } from "gatsby"
import { PostInfo } from "../../fragments/PostInfo";
import Img from "gatsby-image"
import RelatedPosts from "../RelatedPosts";
import PostFooter from "../PostFooter";
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Anchor from "../Anchor";

function createInlineFootnote(anchor, footnotes) {
  const id = anchor.props.id.split("-")[1];

  let candidates;
  if (footnotes.filter) {
    candidates = footnotes;
  } else {
    candidates = [footnotes];
  }
  
  const footnote = candidates.filter((item) => {
    const footnoteId = item.props.id.split("-")[1];
    return id == footnoteId;
  })

  const footnoteBody = footnote[0].props.children;

  return <Sidenote id={id}>{footnoteBody}</Sidenote>
}

function inlineFootnotes(children, footnotes) {
  return React.Children.map(children, child => {
    if (child.props && child.props.mdxType == "sup") {
      return createInlineFootnote(child, footnotes);
    } else if (child.props && child.props.children) {
      return React.cloneElement(child, {
        children: inlineFootnotes(child.props.children, footnotes)
      })
    } else {
      return child;
    }
  })
}


const shortcodes = { 
  Map: Map, 
  Gallery: Gallery, 
  Pull: Pull,
  Aside: Aside,
  FullWidth: FullWidth,
  a: Anchor,
  OutboundLink: OutboundLink,
  wrapper: ({ children, ...props }) => {
    if (!children || !children.forEach) {
      return <>{children}</>;
    }

    let footnotes;
    children.forEach(child => {
      if (child.props.className === 'footnotes') {
        footnotes = child.props.children[1].props.children;
      }
    })
    
    const updatedChildren = inlineFootnotes(children, footnotes);
    return <>{updatedChildren}</>
  },
}

export default function BlogPostTemplate({data, pageContext, children}) {

  const heroImgFluid = data.mdx.frontmatter.heroImage?.childImageSharp?.fluid;

  const image = data.mdx.frontmatter?.imageThumbnail?.childImageSharp?.fluid ||
    data.mdx.frontmatter?.heroImage?.childImageSharp?.fluid;

  let earlier;
  if (pageContext.earlier) {
    earlier = {
      path: pageContext.earlier.node.fields.path,
      title: pageContext.earlier.node.frontmatter.title
    }
  }

  let later;
  if (pageContext.later) {
    later = {
      path: pageContext.later.node.fields.path,
      title: pageContext.later.node.frontmatter.title
    }
  }

  const relatedLinks = [earlier, later].filter(item => item !== undefined);

  const section = data.mdx.fields.sourceName == 'memex-updates' ? 'memex' : data.mdx.fields.sourceName;
  const title = data.mdx.frontmatter.title;
  const subtitle = data.mdx.frontmatter.subtitle;

  return (
    <Layout
      title={data.mdx.frontmatter.title}
      date={data.mdx.frontmatter.date}
      section={section}
      laterPost={later}
      earlierPost={earlier}
    >
      <SEO 
        title={title} 
        subtitle={subtitle}
        image={image?.src}
      />
      <TextContainer>
        <div className="post">
          <div className="post-header">
            {heroImgFluid && (
              <div className="post-header__hero">
                <Img fluid={heroImgFluid} />
                {data.mdx.frontmatter.heroCaption && (
                  <div className="post-header__hero-caption">
                    {data.mdx.frontmatter.heroCaption}
                  </div>
                )}
              </div>
            )}
            <div className="post-header__text">
              <div className="post-header__meta">
                <span className="post-card__date">
                  {data.mdx.frontmatter.date}
                </span>
                {data.mdx.frontmatter.kicker && (
                  <span className="post-card__kicker">
                    {data.mdx.frontmatter.kicker}
                  </span>
                )}
              </div>
              <h1 className="post-header__title">{data.mdx.frontmatter.title}</h1>
              <h2 className="post-header__subtitle">{data.mdx.frontmatter.subtitle}</h2>
            </div>
          </div>
          
          {/* TODO: make this work again
          {
            data.mdx?.tableOfContents?.items && (
              <TableOfContents items={data.mdx.tableOfContents.items} />
            )
          } */}
          
          <div className="content">
            <MDXProvider components={shortcodes}>
              <MDXRenderer>
                {data.mdx.body}
              </MDXRenderer>
            </MDXProvider>
          </div>
        </div>
      </TextContainer>
      <PostFooter post={data.mdx}/>
      <RelatedPosts post={data.mdx}/>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      ...PostInfo
    }
  }
`
