import React, { useState, useCallback } from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

export default function PostFooter({ post }) {
  const section = post.fields.sourceName == 'memex-updates' ? 'memex' : post.fields.sourceName;

  function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  return (
    <div className="post-footer">
      <p>This post was published on {post.frontmatter.date} in <Link to={`/${section}`}>
          {toTitleCase(section)}.
      </Link> {post.frontmatter.tags && (
        <span className="post-footer__tags">
          It is tagged with: {post.frontmatter.tags.map((tag, i) => (
            <span key={i}>
              {i > 0 && ", "}
              {tag}
            </span>
          ))}.
        </span>
      )
        }
      </p>
      <p>Andrew Louis is a software developer based in Toronto. He is currently <Link to={`/memex`}>building a Memex</Link>. You can contact him on Twitter <OutboundLink href="https://twitter.com/hyfen">@hyfen</OutboundLink> or by email <OutboundLink href="mailto:andrew@hyfen.net">andrew@hyfen.net</OutboundLink>.
      </p>
      <p>
        
      </p>
      <p></p>
    </div>
  )
}

