import React, { useState, useCallback } from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

export default function PostThumbnail({post}) {
  const frontmatter = post.frontmatter;

  const link = post.fields.sourceName == 'memex-updates' ? `/memex/updates/${post.frontmatter.slug}` : `/${post.frontmatter.slug}`;

  const body = post.frontmatter.subtitle || post.excerpt
  const image = frontmatter?.imageThumbnail?.childImageSharp?.fluid ??
    frontmatter?.heroImage?.childImageSharp?.fluid;
  const title = frontmatter.title;

  return (
    <div className="post-thumbnail">
      <Link to={link}>
        {image && (
          <div className="post-thumbnail__hero">
            <Img fluid={image} />
          </div>
        )}
        <div className="post-thumbnail__card">
          <div className="post-thumbnail__meta">
            <span className="post-thumbnail__date">
              {frontmatter.date}
            </span>
          </div>
          <h2>
            {title}
          </h2>
        </div>
      </Link>
    </div>
  )
}
