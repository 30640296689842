import React, { useState, useCallback } from "react";
import PostThumbnail from './PostThumbnail';

export default function RelatedPosts({post}) {

  return (
    <div className="related-posts">
      <h2 className="related-posts__title">Similar posts</h2>
      <div className="related-posts__posts">
        {post.relatedReads.map((item, i) => {
          return (
            <PostThumbnail post={item} key={i} />
          )
        })}
      </div>
    </div>
  )
}