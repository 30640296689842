import React, { useState, useEffect, useCallback, useRef } from "react"
import ReactMarkdown from "react-markdown";
import useOnClickOutside from "../hooks/useOnClickOutside";

export default function Sidenote({children, id}) {
  const labelId = `sidenote-${id}`;
  const ref = useRef(null);

  const [ checked, setChecked ] = useState(false);

  useOnClickOutside(ref, () => setChecked(false));

  return (
    <span className="sidenote" ref={ref}>
      <label for={labelId} className="sidenote__toggle-label"><sup className="sidenote__marker"></sup></label>
      <input name="sidenote" onClick={() => setChecked(!checked)} checked={checked} type="checkbox" id={labelId} className="sidenote__toggle"/>
      <span className="sidenote__content">
        {children}
      </span>
    </span>
  )
}
